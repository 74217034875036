.status {
  padding-top: 100px;
  &__title {
    text-align: center;
  }

  &__subtitle {
    margin-top: 20px;
    font-weight: 300;
    text-align: center;
    color: var(--color-text);
  }
}
