:root {
  --color-black: #000000;
  --color-white: #ffffff;
  --color-main: #9d8df1;
  --color-main-light: #e8e3ff;
  --color-body-bg: #f3f3f5;
  --color-gradient-bg: linear-gradient(83.01deg, #7460dc 10.92%, #9d8df1 89.08%);
  --color-text: #3c3c3b;

  // --color-gray: rgba(180, 180, 180, 0.25);
  // --color-gray-light: #c1c1c1;
  // --color-blue: #1a79e5;
  // --color-pink: #f05252;
  // --box-shadow: 2px 5px 25px -3px rgba(180, 180, 180, 0.25);
}
