.footer {
  padding-top: 50px;
  background: var(--color-gradient-bg);

  &__left {
    @media (max-width: (576px)) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__title {
    margin-top: 10px;
    color: var(--color-white);
    font-size: 22px;
    font-weight: 100;
    line-height: 34px;
    text-align: start;
  }

  &__social {
    margin-top: 30px;
  }

  &__name {
    margin-top: 50px;
    font-size: 24px;
    font-weight: 600;
    line-height: 26px;
    color: var(--color-white);
  }

  &__rights {
    margin-top: 100px;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: var(--color-main-light);
    @media (max-width: (576px)) {
      display: none;
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    @media (max-width: (576px)) {
      margin-top: 30px;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    gap: 50px;
    &-block {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    &-title {
      font-family: Soyuz Grotesk;
      font-size: 42px;
      font-weight: 600;
      letter-spacing: 0.03em;
      line-height: 58px;
      color: var(--color-white);
    }

    &-phone {
      font-size: 22px;
      font-weight: 500;
      line-height: 26px;
      color: var(--color-white);
    }

    &-social {
      font-size: 14px;
      font-weight: 300;
      line-height: 22px;
      color: var(--color-main-light);
    }

    &-link {
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      text-decoration-line: underline;
      color: var(--color-white);
    }

    &-address {
      display: flex;
      flex-direction: column;
      gap: 30px;

      &--title {
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        color: var(--color-white);
      }

      &--descr {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: var(--color-main-light);
      }
    }
  }

  &__phone {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../assets/img/footer/footer-phone.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 465px;
    max-width: 420px;
    padding-top: 50px;
    width: 100%;
    @media (max-width: (576px)) {
      margin: 0 auto;
    }

    &-title {
      text-align: center;
    }

    &-links {
      display: flex;
      flex-direction: column;
      padding-top: 30px;
      row-gap: 20px;
    }

    &-docs {
      padding-top: 30px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    &-doc {
      font-weight: 300;
      color: #999;
      text-decoration: underline;
    }
  }
}
