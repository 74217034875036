.tabs {
  padding-top: 70px;

  &__list {
    display: flex;
    flex-direction: column;
    max-width: 342px;
    row-gap: 30px;
    width: 100%;
    @media (max-width: (576px)) {
      column-gap: 10px;
      flex-direction: row;
      max-width: 800px;
      overflow-x: scroll;
      padding: 15px 0;
      width: 100%;
      margin-bottom: 15px;
    }
  }

  &__item {
    position: relative;
    height: 72px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    column-gap: 16px;
    background-color: var(--color-white);
    border: 1px solid var(--color-white);
    border-radius: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    transition: 0.3s ease-out;
    cursor: pointer;
    @media (max-width: (735px)) {
      flex-shrink: 0;
      width: 235px;
    }

    &-descr {
      font-size: 14px;
      font-weight: 500;
    }

    &-check {
      position: absolute;
      top: -15px;
      left: -10px;
      opacity: 0;
      transition: 0.3s ease-out;
      z-index: 10;
    }
  }

  &__imgs {
    position: relative;
    height: 630px;
    width: 330px;
    &-img {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: -20px;
      height: 630px;
      opacity: 0;
      transition: all 0.5s ease-in;
      @media (max-width: (540px)) {
        left: 0;
      }
    }
    @media (max-width: (576px)) {
      margin-top: 150px;
    }
  }

  &__descr {
    position: relative;
    @media (max-width: (576px)) {
    }
    &-txt {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      color: var(--color-text);
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      opacity: 0;
      transition: all 0.5s ease-in;
      @media (max-width: (576px)) {
      }
    }
  }
}

.active {
  opacity: 1;
}

.tabs__item.active {
  border: 1px solid var(--color-main);
}
